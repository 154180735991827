import { getApyRate, getTokenApy } from "src/utils/apy";
import TokenAbi from "../assets/abi/petoverse_token_abi.json";

export const TOKEN_ABI = TokenAbi;

export const CURRENT_CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID as string);
export const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS as string;
export const PAIR_ADDRESS = process.env.REACT_APP_PAIR_ADDRESS as string;
export const TREASURY_ADDRESS = process.env.REACT_APP_TREASURY_ADDRESS as string;
export const INSURANCE_FUND_ADDRESS = process.env.REACT_APP_INSURANCE_FUND_ADDRESS as string;
export const VAULT_ADDRESS = process.env.REACT_APP_VAULT_ADDRESS as string;
export const BUSD_ADDRESS = process.env.REACT_APP_BUSD_ADDRESS as string;
export const REBASE_RATE = 0.0009998;
export const REBASE_PER_DAY = 24;

export const APY = parseFloat((getTokenApy(REBASE_RATE, REBASE_PER_DAY * 365) * 100).toFixed(2));
export const DAY1_ROI = getApyRate(1, APY);
export const DAY5_ROI = getApyRate(5, APY);

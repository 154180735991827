import BigNumber from "bignumber.js";
import { useContext, createContext } from "react";
import { DashboardInfo } from "src/config/types";
import { useTokenStats } from "src/hooks/useTokenStats";

const defaultInfo: DashboardInfo = {
  price: "0",
  timer: {
    timeFinished: false,
    timeLeft: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  },
  account: {
    balance: new BigNumber(0),
    balanceUsd: "0",
    displayBalance: "0",
    day1Usd: "0",
    day5Usd: "0",
    nextRewardAmount: "0",
    nextRewardAmountUSD: "0",
  },
  dashboard: {
    circulatingSupply: "0",
    insuranceFund: "0",
    liquidityBacking: "0",
    marketcap: "",
    poolValue: "0",
    totalSupply: "0",
    treasury: "0",
    vault: "0",
    vaultSupply: "0",
    vaultValue: "0",
  },
  calculator: {
    setPetoAmount: () => {},
    setApy: () => {},
    setPurchasePrice: () => {},
    setFuturePrice: () => {},
    setInvestmentDays: () => {},
    setCurrentAPY: () => {},
    setCurrentFuturePrice: () => {},
    setCurrentPurchasePrice: () => {},
    setMaxBalance: () => {},
    apy: 0,
    futurePrice: "0",
    purchasePrice: "0",
    investmentDays: 0,
    petoAmount: 0,
    initialInvestment: "0",
    currentWealth: "0",
    petoRewardsEstimation: "0",
    potentialReturns: "0",
  },
};

const DashboardInfoContext = createContext<DashboardInfo>(defaultInfo);

const DashboardInfoContextProvider = ({ children }: { children: JSX.Element }) => {
  const info = useTokenStats();

  return <DashboardInfoContext.Provider value={info}>{children}</DashboardInfoContext.Provider>;
};

export { DashboardInfoContext, DashboardInfoContextProvider };

export const useDashboardInfo = () => {
  return useContext(DashboardInfoContext);
};

import React from "react";
import { makeStyles } from "tss-react/mui";
import { Button, Card, Container, Grid, Theme, Typography } from "@mui/material";
import { useDashboardInfo } from "src/context/DashboardInfoContext";
import { APY, DAY1_ROI, REBASE_RATE } from "src/config/config";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    margin: "20px auto 0 auto",
  },
  textRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 10,
    marginRight: 80,
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
    },
  },
  textRowKey: {
    fontWeight: 200,
  },
  card: {
    display: "flex",
    flexFlow: "row",
    justifyContent: "space-between",
    gap: 90,
    alignItems: "center",
    height: "100%",
    minHeight: 120,
    [theme.breakpoints.down("md")]: {
      gap: 20,
    },
  },
  title: {
    textTransform: "uppercase",
    fontSize: "1.2rem",
  },
}));

interface Props {}

const data = [
  ["Current $BABES Price", "$147.50"],
  ["Next Reward Amount", "0 $BABES"],
  ["Next Reward Amount USD", "$0"],
  ["Next Reward Yield", "Next Reward Yield"],
  ["ROI (30-Day Rate)", "11.96%"],
  ["ROI (30-Day Rate) USD", "$0"],
  // ["ROI(5-Day Rate) USD", "$0"],
];

const Account: React.FC<Props> = () => {
  const { classes } = useStyles();
  const {
    timer,
    price,
    account: { balanceUsd, displayBalance, day1Usd, day5Usd, nextRewardAmount, nextRewardAmountUSD },
  } = useDashboardInfo();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={12}>
            <Card className={classes.card}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" className={classes.title}>
                    <b>Total Earned</b>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="thin">$0</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>
                    <b>$800,000 $BABES (4500% INCREASE)</b>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid> */}
          <Grid item xs={12} sm={12}>
            <Card className={classes.card}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" className={classes.title}>
                    APY
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="thin">
                    {APY.toLocaleString()} %
                    <br />
                    <b>Daily ROI {DAY1_ROI.toFixed(2)} %</b>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} lg={4}>
                  <Typography>
                    Your Balance
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <Button variant="contained" sx={{ padding: "2px 15px", fontSize: "1rem" }}>
                    <b>$ {balanceUsd}</b>
                  </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <Typography>
                    <b>{displayBalance} $BABES</b>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card className={classes.card}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h5" className={classes.title}>
                    Next Payout
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="thin">
                    {timer.timeLeft.hours.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}:
                    {timer.timeLeft.minutes.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}:
                    {timer.timeLeft.seconds.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}
                    <br />
                    <Typography component={'span'}>Interest Coming in your wallet</Typography>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12} lg={4}>
                  <Typography>
                    Your next earnings
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <Button variant="contained" sx={{ padding: "2px 15px", fontSize: "1rem" }}>
                    {nextRewardAmountUSD} $
                  </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                  <Typography>
                    <b>{nextRewardAmount} $BABES</b>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card>
              <Grid container>
                <Grid item xs={12} lg={5} className={classes.textRow}>
                  <Typography className={classes.textRowKey}>
                    Current $BABES Price
                  </Typography>
                  <Typography variant="thin">{price}$</Typography>
                </Grid>
                <Grid item xs={12} lg={5} className={classes.textRow}>
                  <Typography className={classes.textRowKey}>
                    Next Reward Amount
                  </Typography>
                  <Typography variant="thin">{nextRewardAmount} $BABES</Typography>
                </Grid>
                <Grid item xs={12} lg={5} className={classes.textRow}>
                  <Typography className={classes.textRowKey}>
                    Next Reward Amount USD
                  </Typography>
                  <Typography variant="thin">{nextRewardAmountUSD} $</Typography>
                </Grid>
                <Grid item xs={12} lg={5} className={classes.textRow}>
                  <Typography className={classes.textRowKey}>
                    Next Reward Yield
                  </Typography>
                  <Typography variant="thin">{(REBASE_RATE * 100).toFixed(5)} %</Typography>
                </Grid>
                <Grid item xs={12} lg={5} className={classes.textRow}>
                  <Typography className={classes.textRowKey}>
                    ROI (30-Day Rate)
                  </Typography>
                  <Typography variant="thin">{day1Usd} $</Typography>
                </Grid>
                <Grid item xs={12} lg={5} className={classes.textRow}>
                  <Typography className={classes.textRowKey}>
                    ROI (30-Day Rate) USD
                  </Typography>
                  <Typography variant="thin">{day5Usd} $</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Account;

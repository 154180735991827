import React from "react";
import { makeStyles } from "tss-react/mui";
import { Theme, Typography } from "@mui/material";
import Logo from "src/assets/logos/logo.png";
import clsx from "clsx";
import DashboardIcon from "src/assets/icons/dashboard.svg";
import AccountIcon from "src/assets/icons/account.svg";
import CalculatorIcon from "src/assets/icons/calculator.svg";
import { useNavigate, useLocation } from "react-router-dom";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "grid",
    gridTemplateRows: "min-content 1fr",
    height: "100vh",
    padding: '0 20px',
  },
  item: {
    paddingLeft: 25,
    textAlign: 'center',
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 50,
    cursor: "pointer",
    marginBottom: 20,
  },
  itemText: {
    fontSize: 18,
    letterSpacing: 1,
    fontWeight: 300,
  },
  itemIcon: {
    marginRight: 10,
    transform: "translateY(3px)",
  },
  activeItem: {
    boxShadow: '1px 1px 7px 2px rgba(255,255,255, 0.4)',
    borderRadius: 20,
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      top: "26%",
      left: 0,
      height: "54%",
      width: 4,
    },
  },
}));

interface Props {
  handleClose?: any;
}

const SideBar: React.FC<Props> = ({ handleClose }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (path: string) => {
    navigate(path);
    handleClose && handleClose(undefined);
  };

  return (
    <div className={classes.root}>
      <img width="80%" style={{ marginLeft: "10%" }} src={Logo} alt="petoverse" />
      <div style={{ height: "100%", paddingTop: 20 }}>
        <div
          className={clsx(classes.item, location.pathname === "/" && classes.activeItem)}
          onClick={() => handleNavigate("/")}
        >
          <Typography className={classes.itemText}>
            <img src={DashboardIcon} className={classes.itemIcon} alt="dashboard" /> Dashboard
          </Typography>
        </div>
        <div
          className={clsx(classes.item, location.pathname.includes("account") && classes.activeItem)}
          onClick={() => handleNavigate("/account")}
        >
          <Typography className={classes.itemText}>
            <img src={AccountIcon} className={classes.itemIcon} alt="account" /> Account
          </Typography>
        </div>
        <div
          className={clsx(classes.item, location.pathname.includes("calculator") && classes.activeItem)}
          onClick={() => handleNavigate("/calculator")}
        >
          <Typography className={classes.itemText}>
            <img src={CalculatorIcon} className={classes.itemIcon} alt="calculator" /> Calculator
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default SideBar;

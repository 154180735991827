import {
  useContract,
  useUSDLp,
  toBigNumber,
  toLowerUnit,
  useEthers,
  useERC20,
  useERC20Balance,
  useReload,
} from "@react-dapp/utils";
import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import {
  APY,
  BUSD_ADDRESS,
  DAY1_ROI,
  DAY5_ROI,
  INSURANCE_FUND_ADDRESS,
  PAIR_ADDRESS,
  REBASE_RATE,
  TOKEN_ABI,
  TOKEN_ADDRESS,
  TREASURY_ADDRESS,
  VAULT_ADDRESS,
} from "src/config/config";
import { DashboardInfo } from "src/config/types";
import { calculateRewards } from "src/utils/apy";
import { useTimer } from "./useTimer";

export const useTokenStats = (): DashboardInfo => {
  const { account } = useEthers();
  const token = useContract(TOKEN_ABI, TOKEN_ADDRESS);
  const busd = useERC20(BUSD_ADDRESS);
  const lp = useUSDLp(PAIR_ADDRESS);
  const { reload, reloadable } = useReload();

  // dashboard
  const [marketcap, setMarketcap] = useState("0");
  const [circulatingSupply, setCirculatingSupply] = useState("0");
  const [totalSupply, setTotalSupply] = useState("0");
  const [liquidityBacking, setLiquidityBacking] = useState("0");
  const [poolValue, setPoolValue] = useState("0");
  const [treasury, setTreasury] = useState("0");
  const [insuranceFund, setInsuranceFund] = useState("0");
  const [vault, setVault] = useState("0");
  const [vaultValue, setVaultValue] = useState("0");
  const [vaultSupply, setVaultSupply] = useState("0");
  const [price, setPrice] = useState<BigNumber>();
  const [lastRebaseTime, setLastRebaseTime] = useState(0);
  const timer = useTimer(lastRebaseTime + 60 * 60);
  const [lastReloadTime, setLastReloadTime] = useState(Date.now());

  // account
  const [balanceUsd, setBalanceUsd] = useState("0");
  const { balance, displayBalance } = useERC20Balance(TOKEN_ADDRESS, 5);
  const [nextRewardAmount, setNextRewardAmount] = useState("0");
  const [nextRewardAmountUSD, setnextRewardAmountUSD] = useState("0");
  const [day1Usd, setDay1Usd] = useState("0");
  const [day5Usd, setDay5Usd] = useState("0");

  // CALCULATOR
  // input values
  const [petoAmount, setPetoAmount] = useState(0);
  const [apy, setApy] = useState(APY);
  const [purchasePrice, setPurchasePrice] = useState("0");
  const [futurePrice, setFuturePrice] = useState("0");
  const [investmentDays, setInvestmentDays] = useState(30);

  // result values
  const [initialInvestment, setInitialInvestment] = useState("0");
  const [currentWealth, setCurrentWealth] = useState("0");
  const [petoRewardsEstimation, setPetoRewardsEstimation] = useState("0");
  const [potentialReturns, setPotentialReturns] = useState("0");

  const setMaxBalance = () => setPetoAmount(parseFloat(displayBalance || "0"));
  const setCurrentAPY = () => setApy(APY);
  const setCurrentPurchasePrice = () => setPurchasePrice(price?.toFixed(2) || "0");
  const setCurrentFuturePrice = () => setFuturePrice(price?.toFixed(2) || "0");

  useEffect(() => {
    if (timer.timeFinished && lastReloadTime + 1 * 60 * 1000 < Date.now()) {
      setLastReloadTime(Date.now());
      reload();
    }
  }, [timer]);

  useEffect(() => {
    const results = calculateRewards(
      petoAmount,
      apy,
      price?.toNumber() ?? 0,
      parseFloat(purchasePrice),
      parseFloat(futurePrice),
      investmentDays
    );
    setInitialInvestment(results.investment.toFixed(2));
    setCurrentWealth(results.currentWealth.toFixed(2));
    setPetoRewardsEstimation(results.rewards.toFixed(2));
    setPotentialReturns(results.returns.toFixed(2));
  }, [petoAmount, apy, purchasePrice, futurePrice, investmentDays]);

  useEffect(() => {
    const fetch = async () => {
      if (lp.baseTokenUsdPrice && token && busd) {
        const _price = lp.baseTokenUsdPrice;
        const _lastRebasedTime = toBigNumber(await token._lastRebasedTime()).toNumber();
        const _totalSupply = toLowerUnit(toBigNumber(await token.getCirculatingSupply()).toFixed(), 5);
        const _pairBalance = toLowerUnit(toBigNumber(await token.balanceOf(PAIR_ADDRESS)).toFixed(), 5);
        const _treasuryBalance = toLowerUnit(toBigNumber(await token.balanceOf(TREASURY_ADDRESS)).toFixed(), 5);
        const _insuranceFundBalance = toLowerUnit(
          toBigNumber(await busd.balanceOf(INSURANCE_FUND_ADDRESS)).toFixed(),
          18
        );
        const _vaultBalance = toLowerUnit(toBigNumber(await token.balanceOf(VAULT_ADDRESS)).toFixed(), 5);
        const _liquidityBacking = toBigNumber(await token.getLiquidityBacking(100)).toFixed();
        const _circulatingSupply = _totalSupply.minus(_pairBalance);
        setLastRebaseTime(_lastRebasedTime);
        setPrice(lp.baseTokenUsdPrice);
        setPurchasePrice(lp.baseTokenUsdPrice.toFixed(2));
        setFuturePrice(lp.baseTokenUsdPrice.toFixed(2));
        setCirculatingSupply(_circulatingSupply.toFormat(0));
        setTotalSupply(_totalSupply.toFormat(0));
        setMarketcap(_circulatingSupply.times(_price).toFormat(0));
        setLiquidityBacking(_liquidityBacking);
        setTreasury(_treasuryBalance.times(_price).toFormat(0));
        setInsuranceFund(_insuranceFundBalance.toFormat(0));
        setVault(_vaultBalance.toFormat(0));
        setVaultValue(_vaultBalance.times(_price).toFormat(0));
        setVaultSupply(_vaultBalance.div(_totalSupply).times(100).toFormat(2));
        setPoolValue(_pairBalance.times(_price).times(2).toFormat(0));
      }
    };
    fetch();
  }, [lp.baseTokenUsdPrice, token, busd, reloadable]);

  useEffect(() => {
    const fetch = async () => {
      if (balance && token && price) {
        setBalanceUsd(toLowerUnit(balance?.times(price)?.toFixed(0) ?? "0", 5).toFormat(2));
        const _rewardAmount = balance.times(REBASE_RATE).div(10000);
        const _day1Usd = balance.times(DAY1_ROI).div(100).times(price).toFormat(5);
        const _day5Usd = balance.times(DAY5_ROI).div(100).times(price).toFormat(5);
        setNextRewardAmount(_rewardAmount.toFixed(2));
        setnextRewardAmountUSD(_rewardAmount.times(price).toFormat(5));
        setDay1Usd(_day1Usd);
        setDay5Usd(_day5Usd);
      }
    };
    fetch();
  }, [balance, token, price]);

  return {
    price: price?.toFormat(2),
    timer,
    dashboard: {
      marketcap,
      liquidityBacking,
      circulatingSupply,
      totalSupply,
      treasury,
      insuranceFund,
      vault,
      vaultValue,
      vaultSupply,
      poolValue,
    },
    account: {
      balance,
      displayBalance,
      balanceUsd,
      nextRewardAmount,
      nextRewardAmountUSD,
      day1Usd,
      day5Usd,
    },
    calculator: {
      setPetoAmount,
      setApy,
      setPurchasePrice,
      setFuturePrice,
      setInvestmentDays,
      setMaxBalance,
      setCurrentAPY,
      setCurrentPurchasePrice,
      setCurrentFuturePrice,
      petoAmount,
      apy,
      purchasePrice,
      futurePrice,
      investmentDays,
      initialInvestment,
      currentWealth,
      petoRewardsEstimation,
      potentialReturns,
    },
  };
};

import React from "react";
import { makeStyles } from "tss-react/mui";
import { Slider, Theme, Typography } from "@mui/material";
import { useDashboardInfo } from "../../../context/DashboardInfoContext";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingTop: 30,
  },
  textRow: {
    maxWidth: "500px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  textRowKey: {
    fontWeight: 200,
  },
}));

interface Props {}

const ResultSide: React.FC<Props> = () => {
  const { classes } = useStyles();
  const {
    calculator: {
      currentWealth,
      initialInvestment,
      petoRewardsEstimation,
      potentialReturns,
      investmentDays,
      setInvestmentDays,
    },
  } = useDashboardInfo();

  return (
    <div className={classes.root}>
      <Slider
        value={investmentDays}
        onChange={(_, v) => setInvestmentDays(v as number)}
        color="primary"
        valueLabelDisplay="on"
        defaultValue={30}
        max={365}
        valueLabelFormat={(value) => value + " Days"}
      />
      <div style={{ marginTop: 20 }}>
        <div className={classes.textRow}>
          <Typography className={classes.textRowKey}>
            Your initial investment
          </Typography>
          <Typography variant="thin">${initialInvestment}</Typography>
        </div>
        <div className={classes.textRow}>
          <Typography className={classes.textRowKey}>
            Current wealth
          </Typography>
          <Typography variant="thin">${currentWealth}</Typography>
        </div>
        <div className={classes.textRow}>
          <Typography className={classes.textRowKey}>
            $BABES rewards estimation
          </Typography>
          <Typography variant="thin">{petoRewardsEstimation} $BABES</Typography>
        </div>
        <div className={classes.textRow}>
          <Typography className={classes.textRowKey}>
            Potential return
          </Typography>
          <Typography variant="thin">${potentialReturns}</Typography>
        </div>
      </div>
    </div>
  );
};

export default ResultSide;

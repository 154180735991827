import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import theme from "./utils/theme";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "./assets/fonts/LondrinaSolid-Black.ttf";
import "./assets/fonts/LondrinaSolid-Thin.ttf";
import { WalletProvider } from "@react-dapp/wallet";
import { CURRENT_CHAIN_ID } from "./config/config";

ReactDOM.render(
  <React.StrictMode>
    {/* @ts-ignore */}
    <WalletProvider config={{ chainId: CURRENT_CHAIN_ID }}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <App />
            <CssBaseline />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </WalletProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

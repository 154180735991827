import React from "react";
import { makeStyles } from "tss-react/mui";
import { Container, Grid, Theme, Typography, Card } from "@mui/material";
import InputSide from "./components/InputSide";
import ResultSide from "./components/ResultSide";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 50,
    marginTop: 30
  },
  head: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    }
  }
}));

interface Props {}

const Calculator: React.FC<Props> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.head}>
          <Typography textAlign="center" variant="h5" style={{ marginRight: 20, textTransform: 'uppercase', fontWeight: '900' }}>
            Calculator
          </Typography>
          <Typography textAlign="center" variant="thin" style={{lineHeight: '35px'}}>Estimate your returns</Typography>          
        </div>
        <Card>
        <Container maxWidth="md">
        <form>
          <Grid container spacing={2} style={{ marginTop: 0 }}>
            <Grid item xs={12} sm={12}>
              <InputSide />
            </Grid>
            <Grid item xs={12} sm={12}>
              <ResultSide />
            </Grid>
          </Grid>
        </form>
        </Container>
        </Card>
        
      </Container>
    </div>
  );
};

export default Calculator;


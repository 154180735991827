import React from "react";
import { makeStyles } from "tss-react/mui";
import { Button, Container, Theme, Typography } from "@mui/material";
import Routes from "src/Routes";
import clsx from "clsx";
import { useWallet } from "@react-dapp/wallet";
import { useEthers } from "@react-dapp/utils";
import { useDashboardInfo } from "src/context/DashboardInfoContext";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: "100%",
    width: "100%",
    maxWidth: "90%",
    margin: "0 auto",
    top: 16,
    position: "relative",
    display: "grid",
    paddingBottom: 20,

    gridTemplateRows: "min-content 1fr",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      top: 0,
    },
  },
  linksContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 20,
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  leftLinks: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: 20,
  },
  links: {
    cursor: "pointer",
    boxShadow: "1px 1px 4px 1px rgba(255,255,255, 0.4)",
    borderRadius: "12px",
  },
  connect: {
    padding: "3px 15px",
  },
}));

interface Props {}

const Body: React.FC<Props> = () => {
  const { classes } = useStyles();
  const { setOpen, deactivate } = useWallet();
  const { displayAccount } = useEthers();
  const { price } = useDashboardInfo();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.linksContainer}>
        <div className={classes.leftLinks}>
          <Button variant="contained" focusRipple={false} sx={{ padding: "3px 40px" }} className={classes.links}>
            $BABES {price}
          </Button>
          <a href={"https://www.dextools.io/app/bsc/pair-explorer/0xf26759a29ea312d05e8b7d864e20c70d46a8a500"}>
            <Button variant="outlined" sx={{ padding: "3px 40px" }} className={classes.links}>
              Buy on DexTools
            </Button>
          </a>
          <a href="https://poocoin.app/tokens/0x4b78bd1e38fc5d2dd43a0c9326a20e7f9099c95a">
            <Button variant="outlined" sx={{ padding: "3px 40px" }} className={classes.links}>
              Charts
            </Button>
          </a>
        </div>
        <Button
          onClick={() => (displayAccount ? deactivate() : setOpen(true))}
          variant="outlined"
          className={clsx(classes.links, classes.connect)}
        >
          {displayAccount ? displayAccount : "Connect Wallet"}
        </Button>
      </Container>
      <Routes />
    </div>
  );
};

export default Body;

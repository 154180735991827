import { REBASE_PER_DAY } from "src/config/config";

export const getTokenApy = (rewardYeild: number, compoundingFrequency: number) => {
  return Math.pow(1 + rewardYeild, compoundingFrequency);
};

export const getApyRate = (days: number, annualApy: number) => {
  let apyRate = 1;
  const rate = Math.pow(annualApy, 1 / (REBASE_PER_DAY * 365)) - 1;
  for (let i = 0; i < REBASE_PER_DAY * days; i++) {
    apyRate += apyRate * rate;
  }
  return apyRate;
};

export const calculateRewards = (
  amount: number,
  annualApy: number,
  currentPrice: number,
  purchasePrice: number,
  futurePrice: number,
  days: number
) => {
  const apy = getApyRate(days, annualApy / 100);
  return {
    investment: amount * purchasePrice,
    currentWealth: amount * currentPrice,
    rewards: amount * apy,
    returns: amount * apy * futurePrice,
  };
};

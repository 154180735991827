import { UtilsProvider } from "@react-dapp/utils";
import { useEagerConnect, useWallet } from "@react-dapp/wallet";
import "./App.css";
import AppContainer from "./components/AppContainer/AppContainer";
import { DashboardInfoContextProvider } from "./context/DashboardInfoContext";

function App() {
  const { library } = useWallet();
  useEagerConnect(true);
  return (
    <div>
      <UtilsProvider
        config={{
          provider: library,
          rpcUrl: "https://bsc-dataseed.binance.org/",
          wrappedNative: {
            address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            symbol: "WBNB",
            name: "WBNB",
            decimals: 18,
          },
          usd: {
            address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
            name: "BUSD",
            symbol: "BUSD",
            decimals: 18,
          },
          nativeUsdLp: {
            name: "LP",
            address: "0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16",
            symbol: "LP",
            decimals: 18,
          },
        }}
      >
        <DashboardInfoContextProvider>
          <AppContainer />
        </DashboardInfoContextProvider>
      </UtilsProvider>
      {/* <BurgerMenu /> */}
      {/* <SocialShare /> */}
    </div>
  );
}

export default App;

import React from "react";
import { makeStyles } from "tss-react/mui";
import { Theme, Typography } from "@mui/material";
import InstagramIcon from "src/assets/icons/instagram.svg";
import TwitterIcon from "src/assets/icons/twitter.svg";
import TelegramIcon from "src/assets/icons/telegram.svg";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    "& img": {
      width: 25,
      height: 25,
    },
  },
}));

interface Props {}

const ShareIcons: React.FC<Props> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" fontWeight={"bold"}>
        SOCIALS
      </Typography>
      <a href="https://t.me/bomberbabes">
        <img src={TelegramIcon} alt="" />
      </a>
      <a href="https://twitter.com/bomberbabesio?s=21&t=eG1sxc9F9Vv_PcVxhdCoHA">
        <img src={TwitterIcon} alt="twitter" />
      </a>
      <a href="https://instagram.com/bomberbabes_io?igshid=YmMyMTA2M2Y=">
        <img src={InstagramIcon} alt="instagram" />
      </a>
      {/* <a href="https://</div>www.google.com">
        <img src={YoutubeIcon} alt="discord" />
      </a> */}
      {/* <a href="https://</div>www.google.com">
        <img src={MediumIcon} alt="discord" />
      </a> */}
      {/* <a href="https://</div>www.google.com">
        <img src={RedditIcon} alt="discord" />
      </a> */}
    </div>
  );
};

export default ShareIcons;

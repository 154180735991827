import React from "react";
import { makeStyles } from "tss-react/mui";
import { Button, Card, Grid, TextField, Theme, Typography } from "@mui/material";
import { useDashboardInfo } from "../../../context/DashboardInfoContext";
import { APY } from "src/config/config";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  card: {
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexFlow: "column",
    minHeight: 100,
    boxShadow: "none",
  },
  textarea: {
    background: theme.palette.secondary.main,
  },
}));

interface Props {}

const InputSide: React.FC<Props> = () => {
  const { classes } = useStyles();
  const {
    price,
    account: { displayBalance },
    calculator: {
      setApy,
      setFuturePrice,
      setPetoAmount,
      setPurchasePrice,
      setCurrentAPY,
      setCurrentFuturePrice,
      setCurrentPurchasePrice,
      setMaxBalance,
      apy,
      futurePrice,
      petoAmount,
      purchasePrice,
    },
  } = useDashboardInfo();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Card className={classes.card}>
            <Typography variant="h6" textTransform={"uppercase"} textAlign="center">
              $BABES price
            </Typography>
            <Typography variant="h4" fontWeight={100}>
              ${price}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Card className={classes.card}>
            <Typography variant="h6" textTransform={"uppercase"} textAlign="center">
              APY
            </Typography>
            <Typography variant="h4" fontWeight={100}>
              {APY.toLocaleString()} %
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Card className={classes.card}>
            <Typography variant="h6" textTransform={"uppercase"} textAlign="center">
              Your $BABES balance
            </Typography>
            <Typography variant="h4" fontWeight={100}>
              $ {displayBalance}
            </Typography>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={6} sx={{ mb: 0 }}>
          <Typography style={{ marginBottom: 10, fontWeight: 600 }} textTransform={"uppercase"} textAlign="center">
            $BABES Amount
          </Typography>
          <TextField
            value={petoAmount}
            onChange={(e) => setPetoAmount(parseFloat(e.target.value))}
            variant="outlined"
            fullWidth
            type="number"
            InputProps={{
              endAdornment: (
                <Button onClick={setMaxBalance} sx={{ zIndex: 100 }}>
                  <b>Max</b>
                </Button>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{ mb: 0 }}>
          <Typography style={{ marginBottom: 10, fontWeight: 600 }} textTransform={"uppercase"} textAlign="center">
            APY (%)
          </Typography>
          <TextField
            value={apy}
            onChange={(e) => setApy(parseFloat(e.target.value))}
            variant="outlined"
            fullWidth
            type="number"
            InputProps={{
              endAdornment: (
                <Button onClick={setCurrentAPY} sx={{ zIndex: 100 }}>
                  <b>Current</b>
                </Button>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{ mb: 0 }}>
          <Typography style={{ marginBottom: 10, fontWeight: 600 }} textTransform={"uppercase"} textAlign="center">
            $BABES price at purchase ($)
          </Typography>
          <TextField
            value={purchasePrice}
            onChange={(e) => setPurchasePrice(e.target.value)}
            variant="outlined"
            fullWidth
            type="number"
            InputProps={{
              endAdornment: (
                <Button onClick={setCurrentPurchasePrice} sx={{ zIndex: 100 }}>
                  <b>Current</b>
                </Button>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} sx={{ mb: 0 }}>
          <Typography style={{ marginBottom: 10, fontWeight: 600 }} textTransform={"uppercase"} textAlign="center">
            Future $BABES price ($)
          </Typography>
          <TextField
            value={futurePrice}
            onChange={(e) => setFuturePrice(e.target.value)}
            variant="outlined"
            fullWidth
            type="number"
            InputProps={{
              endAdornment: (
                <Button onClick={setCurrentFuturePrice} sx={{ zIndex: 100 }}>
                  <b>Current</b>
                </Button>
              ),
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default InputSide;

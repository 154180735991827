import { createTheme, responsiveFontSizes  } from "@mui/material";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    thin: true;
  }
}

const primaryColor = "#CF165D";
const secondaryColor="#FE72DE"
const bgColor = "linear-gradient(90deg, #410D48, #8D174F, #E02256)";

let theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'LondrinaSolid',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "white",
          padding: 0,
          minWidth: 20,
          textTransform: "none",

          "&:hover": {
            boxShadow: "1px 1px 4px 1px rgba(255,255,255,0.4)",
            border: 'none',
          }
        },
        contained: {
          background: '#FE72DE',
          borderRadius: 12,

          "&:hover": {
            background: secondaryColor,
          }
        },
        outlined: {
          boxShadow: '1px 1px 7px 2px rgba(255,255,255, 0.4)',
          border: 'none',
        }
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
      variants: [
        {
          props: { variant: "thin" },
          style: {
            fontFamily: "LondrinaSolidThin",
            fontWeight: 100,
          },
        },
        {
          props: { variant: "h4" },
          style: {
            fontFamily: "LondrinaSolidThin",
            fontWeight: 100,
          }
        }
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          background: "white",
          "& svg": {
            fill: primaryColor,
          },
          "&:hover": {
            "& svg": {
              fill: "white",
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: 16,
          background: 'transparent',
          boxShadow: '1px 1px 7px 2px rgba(255,255,255, 0.4)',
          borderWidth: "1px",
          borderColor: 'none',
          borderRadius: 12,
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            background: primaryColor,
            borderColor: "white",
          },
        },
      ],
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: bgColor,
          width: 250,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: {
          fill: primaryColor,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& div": {
            height: 48,
          },
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            "& input": {
              fontWeight: 400,
              fontFamily: "LondrinaSolid",
              zIndex: 100,              
            },
            "& fieldset": {
              borderRadius: 12,
              backgroundColor: secondaryColor,
            },
          },
        
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        thumbColorPrimary: {
          background: 'white',
          border: `4px solid ${secondaryColor}`
        },
        track: {
          background: secondaryColor,
          border: "white",
        },
        rail: {
          background: "transparent",
          height: 9,
          border: "2px solid white",
        },
        valueLabel: {
          background: "none",
          fontWeight: 300,
          fontSize: "1.2rem",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "::-webkit-scrollbar": {
          width: 0,
        },
        "::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "::-webkit-scrollbar-thumb": {
          background: primaryColor,
        },
        a: {
          textDecoration: "none",
          color: "inherit",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme)

export default theme;

import React from "react";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";
import Body from "./components/Body";
import SideBar from "../SideBar/SideBar";
import ShareIcons from "../ShareIcons/ShareIcons";
import Drawer from "src/components/Drawer/Drawer";

const width = 250;

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: "relative",
    height: "100vh",
    width: '100%',
  },
  bodyWrapper: {
    height: "calc(100vh - 100px)",
    width: `calc(100vw - ${250}px)`,
    position: "absolute",
    top: 0,
    right: 0,
    
    "& #drawer": {
      display: "none",
      zIndex: 100
    },
    [theme.breakpoints.down("md")]: {
      "& #drawer": {
        display: "initial",
        // overflowY: "scroll",
      },
      height: "auto",
      width: "100%",
      overflowY: "hidden",
      left: 0,
      borderBottomLeftRadius: 0,
    },
  },
  iconsContainer: {
    position: "absolute",
    bottom: 50,
    left: '3%',
  },
  sideWrapper: {
    [theme.breakpoints.down("md")]: {
      display: 'none'
    }
  }
}));

interface Props {}

const AppContainer: React.FC<Props> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.sideWrapper} style={{ width: width }}>
        <SideBar />
      <div className={classes.iconsContainer}>
        <ShareIcons />
      </div>
      </div>
      <div className={classes.bodyWrapper}>
        <Drawer />
        <Body />
      </div>
    </div>
  );
};

export default AppContainer;

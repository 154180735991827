import * as React from "react";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import SideBar from "../SideBar/SideBar";
import ShareIcons from "../ShareIcons/ShareIcons";
import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material";


type Anchor = "top" | "left" | "bottom" | "right";


const useStyles = makeStyles()((theme: Theme) => ({
  iconsContainer: {
    position: "absolute",
    bottom: 50,
    left: '20%',
  }
}))

export default function TemporaryDrawer() {
  const {classes} = useStyles()

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div id="drawer">
      <span
        style={{
          position: "fixed",
          top: 10,
          left: 10,
          zIndex: 10,
          height: 47,
          width: 47,
          background: "rgba(0,0,0,0.8)",
          borderRadius: 5,
        }}
        className="center"
        onClick={toggleDrawer("left", true)}
      >
        <MenuIcon color="primary" fontSize="large" />
      </span>
      <Drawer anchor={"left"} open={state["left"]} onClose={toggleDrawer("left", false)}>
        <SideBar handleClose={toggleDrawer("left", false)} />
        <div className={classes.iconsContainer}>
          <ShareIcons />
        </div>
      </Drawer>
    </div>
  );
}

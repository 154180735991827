import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import Dashboard from "src/screens/Dashboard/Dashboard";
import Account from "src/screens/Account/Account";
import Calculator from "src/screens/Calculator/Calculator";

interface Props {}

const Routes: React.FC<Props> = () => {
  return (
    <Switch>
      <Route path="/" element={<Dashboard />} />
      <Route path="/account" element={<Account />} />
      <Route path="/calculator" element={<Calculator />} />
    </Switch>
  );
};

export default Routes;

import React from "react";
import { makeStyles } from "tss-react/mui";
import { Button, Card, Container, Grid, Theme, Typography } from "@mui/material";
import { useDashboardInfo } from "../../context/DashboardInfoContext";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    margin: "0 auto",
  },
  card: {
    width: "100%",
    height: "100%",
    padding: "5% 2.5%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexFlow: "column",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  payout: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
  },
  tag: {
    position: "absolute",
    marginLeft: "65%",
    padding: "5px 20px",
    marginBottom: "10px",
    lineHeight: "20px",
    top: "5%",
    right: "2%",
  },
  title: {
    textTransform: "uppercase",
    marginTop: "26px",
  },
}));

interface Props {}

const Dashboard: React.FC<Props> = () => {
  const { classes } = useStyles();
  const {
    price,
    timer,
    dashboard: {
      marketcap,
      circulatingSupply,
      totalSupply,
      liquidityBacking,
      insuranceFund,
      treasury,
      vault,
      vaultValue,
      vaultSupply,
      poolValue,
    },
  } = useDashboardInfo();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" textAlign="center" fontWeight="bold" mb={1}>
              GENERAL OVERVIEW
            </Typography>
            <Card className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.title} variant="h6" align="center">
                    MARKET CAP
                  </Typography>
                  <Typography variant="h4" fontWeight={100} align="center">
                    $ {marketcap}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.title} variant="h6" align="center">
                    CIRCULATING SUPPLY
                  </Typography>
                  <Typography variant="h4" fontWeight={100} align="center">
                    $ {circulatingSupply}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.title} variant="h6" align="center">
                    $BABES PRICE
                  </Typography>
                  <Typography variant="h4" fontWeight={100} align="center">
                    $ {price}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Typography className={classes.title} variant="h6" align="center">
                    Total Supply
                  </Typography>
                  <Typography variant="h4" fontWeight={100} align="center">
                    {totalSupply} $BABES
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid container spacing={4} marginTop={2}>
            <Grid item xs={12} className={classes.payout}>
              <Typography textAlign="center" fontWeight="bold" textTransform={"uppercase"} mb={1}>
                Next Payout
              </Typography>
              <Button variant="contained" disableElevation sx={{ padding: "5px 45px" }}>
                {timer.timeLeft.hours.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}:
                {timer.timeLeft.minutes.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}:
                {timer.timeLeft.seconds.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}
              </Button>
            </Grid>
          </Grid>
          <Typography variant={"h5"} textAlign="center" width="100%" fontWeight="bold" mt={7}>
            VAULT STATS
          </Typography>
          <Grid container spacing={2} marginTop={1} pl={2}>
            <Grid item xs={12} sm={4}>
              <Card className={classes.card}>
                {/* <Button variant="contained" disableElevation className={classes.tag}>
                  +3.45%
                </Button> */}
                <Typography className={classes.title} variant="h6" textAlign={"left"}>
                  Pool Value
                </Typography>
                <Typography variant="h4" fontWeight={100} style={{ marginTop: 1, marginBottom: 2 }}>
                  $ {poolValue}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className={classes.card}>
                {/* <Button variant="contained" disableElevation className={classes.tag}>
                  +3.45%
                </Button> */}
                <Typography className={classes.title} variant="h6" textAlign={"left"}>
                  Vault Supply
                </Typography>
                <Typography variant="h4" fontWeight={100} style={{ marginTop: 1, marginBottom: 2 }}>
                  {vault} $BABES
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className={classes.card}>
                {/* <Button variant="contained" disableElevation className={classes.tag}>
                  +3.45%
                </Button> */}
                <Typography variant="h6" className={classes.title} textAlign={"left"}>
                  Vault Value USD
                </Typography>
                <Typography variant="h4" fontWeight={100} style={{ marginTop: 1, marginBottom: 2 }}>
                  $ {vaultValue}
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Dashboard;
